<template>
  <div class="detail-inner">
    <div class="function-mian">
      <div class="function-header">
        选择城市：
      </div>
      <el-tree
        ref="areaTree"
        v-loading="loading.tab"
        :data="cityList"
        node-key="code"
        show-checkbox
        :default-checked-keys="cityInfo"
        :expand-on-click-node="false"
      >
        <span slot-scope="{ node, data }" class="custom-tree-node">
          <span class="function-tree">{{ data.name }}</span>
        </span>
      </el-tree>
    </div>
  </div>
</template>

<script>
import axios from "axios";

class CityItem {
  code = ''
  name = ''
}
export default {
  name: 'IcsCityInner',
  props: {
    cityInfo: {
      type: Array,
      default () {
        return []
      }
    },
    viewCity: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cityList: [],
      loading: {
        tab: false
      }
    }
  },
  watch: {},
  created () {
    this.getCityList()
  },
  methods: {
    getCityList () {
      this.loading.tab = true
      axios.get('/tenant/v1/mstDictInfo/selectDictInfo/area').then(res => {
        const cityData = JSON.stringify(res.data.data)
        const optionsJson = JSON.parse(cityData)
        optionsJson.map((item, index) => {
          item.children = item.subList
          if (this.viewCity) {
            item.disabled = true
            if (item.children) {
              item.children.forEach(sub => {
                sub.disabled = true
              })
            }
          }
        })
        this.cityList = optionsJson
      }).finally(() => {
        this.loading.tab = false
      })
    },
  }
}
</script>

<style scoped>

</style>
